<template>
  <section class="views">
    <headers></headers>
    <div class="views_content">
      <div class="views_banner">
        <img :src="require('@/assets/image/hodgepodge/h5banner.png')" alt="" />
      </div>

      <div class="views_uls">
        <div class="back_box_uls">
          <div class="backUls_box">
            <div class="ulstitle">
              <img
                :src="require('@/assets/image/hodgepodge/title1.png')"
                alt=""
              />
            </div>

            <div class="uls_list">
				<!-- v-if="false" -->
              <div class="organizersMessage" > 
                <img :src="require('@/assets/image/xiao/t2.png')" alt="" /> 
                <img :src="require('@/assets/image/xiao/t1.png')" alt="" />
                <img :src="require('@/assets/image/xiao/t4.png')" alt="" />
                <img :src="require('@/assets/image/xiao/t3.png')" alt="" />
              </div>

              <div class="posters_text">
                <ul>
                  <li>
                    <div class="posters_text_left">贊助機構：</div>
                    <div class="posters_text_right">曾憲備慈善基金</div>
                  </li> 
                  <li>
                    <div class="posters_text_left">主辦機構：</div>
                    <div class="posters_text_right">曾憲備慈善基金、新界校長會、中文路（香港）有限公司</div>
                  </li> 
                  <li>
                    <div class="posters_text_left">合辦機構：</div>
                    <div class="posters_text_right">民建聯及郭玲麗立法會議員辦事處、活力慈善基金</div>
                  </li>
                  <li>
                    <div class="posters_text_left">承辦機構：</div>
                    <div class="posters_text_right">中文路（香港）有限公司</div>
                  </li> 
                  <li>
                    <div class="posters_text_left">支持機構：</div>
                    <div class="posters_text_right">文冬漢語普通話中心、北京廣播電視台北京廣播影視培訓中心、梨木樹苗趣社、匯聚青年協會、屯門婦聯、香港福建社團聯會新界東北分會、慈祐服務社、青田之優音樂藝術學院 iYoung Music Academy、生命樂章慈善基金 Eternal Life Music Charity Foundation、紅磡居民服務協會、紅磡婦女會、藝慧芸集、香港浙江省同鄉會聯合會、仁濟醫院王華湘中學</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="backUls_box2" ref="timeSlide">
            <div class="timeBox">
              <div
                class="timeBox_list"
                v-for="(item, index) in tap"
                :key="index"
                :ref="`tab${index}Ref`"
              >
                <div
                  v-if="index === xuanSelect"
                  style="display: flex"
                  id="target"
                >
                  <span class="selectTime" @click="tabBut(index)"
                    >當前階段</span
                  >
                </div>

                <strong v-else>
                  <span
                    :class="select == index ? 'select' : 'selectdefault'"
                    class="timeBox_dian"
                    @click="tabBut(index)"
                  ></span>
                </strong>

                <div class="timeBox_top"  :class="index!=select?'':'timeBox_topselect'" @click="tabBut(index)">
                  <span v-if="index!=select"> {{ item.name }} </span>
                  <img
                    :src="require('@/assets/image/qp/'+(index+1)+'.png')"
                    alt=""
                    v-else
                  />
                </div>

                <div class="timeBox_bottom"  @click="tabBut(index)">
                  <strong v-html="item.tiem"> </strong>
                </div>
              </div>
            </div>
          </div>

          <div class="backUls_box3">
            <ul>
              <li
                v-for="(item, index) in tap"
                :key="index"
                v-if="index == select"
              >
                <div class="backUls_box3_list">
                  {{ item.content.title }}
                </div>
                <div class="backUls_box3_list2" v-html="item.content.value"></div>
                <!-- 報名尚未開始 -->
                <!-- 在線報名 -->
                <!-- <div class="backUls_box3_list3" v-if="xuanSelect == select" @click="baoming()">在線報名</div> -->
                <!-- <div class="backUls_box3_list3" v-if="xuanSelect == select">複賽結束</div> -->
              </li>
            </ul>
          </div>

          <div class="backUls_box4">
            <div class="backUls_box4_content">
              <div class="ulstitle">
                <img
                  :src="require('@/assets/image/hodgepodge/title2.png')"
                  alt=""
                />
              </div>
              <div class="backUls_box4_nr">
                <div class="backUls_box4_nr_list">
                  香港本地（2023-2024學年）就讀K1至中三的學生
                </div>
                <div class="backUls_box4_nr_uls">
                  <div class="backUls_box4_nr_uls_list bz_color2">
                    幼稚園初級組
                    <div class="select_bz">K1-K2</div>
                  </div>
                  <div class="backUls_box4_nr_uls_list bz_color">
                    幼稚園高級組
                    <div class="select_bz">K3</div>
                  </div>
                  <div class="backUls_box4_nr_uls_list bz_color2">
                    小學初級組
                    <div class="select_bz">P1-P2</div>
                  </div>
                  <div class="backUls_box4_nr_uls_list bz_color">
                    小學中級組
                    <div class="select_bz">P3-P4</div>
                  </div>
                  <div class="backUls_box4_nr_uls_list bz_color2">
                    小學高級組
                    <div class="select_bz">P5-P6</div>
                  </div>
                  <div class="backUls_box4_nr_uls_list bz_color">
                    初中組
                    <div class="select_bz">S1-S3</div>
                  </div>
                </div>

                <div class="backUls_box4_remarks">
                  比賽將根據不同年齡段，分為6個組別進行。
                </div>
              </div>
            </div>
          </div>
          <!-- 阶段 -->
          <div class="backUls_box5">
            <div class="backUls_box5_content">
              <div class="ulstitle">
                <img
                  :src="require('@/assets/image/hodgepodge/title3.png')"
                  alt=""
                />
              </div>
              <div class="backUls_box5_nr">
                <div class="backUls_box5_nr_title">初賽階段</div>
                <div class="backUls_box5_nr_content">
                  <span>初賽</span>報名人數不限。各組別分數排名<span>前100名</span>將獲得複賽資格，若出現同分同名次情況，可同時進入複賽。
                </div>

                <div class="backUls_box5_nr_title">複賽階段</div>
                <div class="backUls_box5_nr_content">
                  <span>複賽</span>人數為每組排名前100名，錄製並上傳朗誦影片，複賽各組別分數排名<span>前3名</span>將獲得進入總決賽資格。
                </div>

                <div class="backUls_box5_nr_title">總決賽階段</div>
                <div class="backUls_box5_nr_content">
                  <span>總決賽</span>人數為每組3人，共6組，總人數為18人。六組各自評出金獎1名、銀獎1名、銅獎1名及優異奬若干名。
                </div>
              </div>
            </div>
          </div>
          <!-- 比赛详情  -->
          <div class="backUls_box5">
            <div class="backUls_box5_content">
              <div class="ulstitle">
                <img
                  :src="require('@/assets/image/hodgepodge/title4.png')"
                  alt=""
                />
              </div>
              <div class="backUls_box5_nr">


                <div class="backUls_box5_nr_title">比賽安排</div>
                <div class="backUls_box5_nr_content_2">
                  <ul>
                    <li>
                      <span>初賽</span
                      >要求各組參賽者使用<span>中文路APP程式</span>進行普通話語音測試。
                    </li>

                    <li>
                      <span>複賽</span
                      >要求各組參賽者使用當前組別官方指定誦材進行<span>朗誦影片錄製</span>。
                    </li>

                    <li>
                      <span>總決賽暨頒獎典禮</span
                      >要求各組參賽者使用當前組別指定誦材，抽籤進行<span>現場對決比賽</span>。
                    </li>
                  </ul>
                </div>  
                <div class="backUls_box5_nr_title">比賽誦材</div>
                <div class="backUls_box5_nr_content_2"> 
					<ul> 
					   <li>
					    為了所有參賽者能在同一時間準備比賽，所有誦材會於開始比賽時同步公佈，我們亦會提前通過電郵通知所有參賽者，屆時請各位注意更新App並開始比賽。
					   </li>
					   <li>
					 	初賽的誦材將於<span>9月1日</span>同步發佈，目前您可以在App内練習朗誦內容，練習内容難度參考正式比賽，練習測試之成績僅供參考，系統僅以發音標準程度提供參考結果，並不代表比賽的最終成績。
					   </li> 
					</ul>
				
                  <div class="a_view" @click="socai()">點擊下載比賽誦材</div> 
                </div> 
              </div>
            </div>
          </div>

          <!-- 參賽須知 -->
          <div class="backUls_box5">
            <div class="backUls_box5_content">
              <div class="ulstitle">
                <img
                  :src="require('@/assets/image/hodgepodge/title5.png')"
                  alt=""
                />
              </div>
              <div class="backUls_box5_nr">


                <div class="backUls_box5_nr_title">報名方法</div>
                <div class="backUls_box5_nr_content_2">
                  <ul>
                    <li>
                      參賽者需要在<font>2023年9月22日 23:59前</font>使用本次活動之官方活動網站或官方WhatsApp 完成報名 
                    </li>
                    <li> 
                      參賽者所填報的姓名必須與香港身份證/出生證明書相同。 
                    </li> 
                  </ul>
                </div>   

                <div class="backUls_box5_nr_title">報名確認</div>
                <div class="backUls_box5_nr_content_2">
                  <ul>
                    <li>
                      當中文路收妥報名表並審核無誤後，7個工作天內便會向參賽者發出報名成功確認電郵。參賽者應細閱電郵內的一切注意事項。
                    </li> 
                    <li>
                      同一電話號碼可用於多位參賽者報名（只適用於一個家庭當中有多位參賽者）。
                    </li>
                    <li>
                      請報名的參賽者時刻留意主辦機構的聯絡資訊（包括但不限於：SMS短信、E-mail郵件、電話或WhatsApp等），如錯過主辦方發出的通知資訊所導致的問題主辦方概不負責。
                    </li>
                  </ul>
                </div>   

                <div class="backUls_box5_nr_title">注意事項</div>
                <div class="backUls_box5_nr_content_2">
                  <ul>
                    <li>
                      所有比賽階段的表演者必須為參賽者本人，如審核發現非參賽者本人錄製或出席現場比賽之情況，將被取消參賽資格，予以失格公示。
                    </li>
                    <li> 
                      凡是報名比賽的參賽者，等同於同意主辦方將其參賽影片使用在不同管道媒體進行展示宣傳。
                    </li> 
                    <li>
                      詳細的「比賽章程」請點擊下方鏈接
                    </li> 
                  </ul>
                </div>   
                
                <div class="backUls_box5_nr_content_2" > 
                  <div class="a_view" @click="zhangcheng()">點擊下載比賽章程</div> 
                </div> 
              </div>
            </div>
          </div>

          <!-- 參賽獎項 -->
          <div class="backUls_box5">
            <div class="backUls_box5_content">
              <div class="ulstitle">
                <img :src="require('@/assets/image/hodgepodge/title6.png')" alt=""  />
              </div>
              <div class="backUls_box5_nr"> 
                <div class="backUls_box5_nr_content_3">
                  <div class="backUls_box5_nr_content_3_view1 bo_color">
                        <h1>金獎<span>(各組別1名)</span></h1>
                        <ul> 
                          <li>2,000港元現金</li>
                          <li>獎盃及獲獎證書</li>
                          <li>1份學習禮包</li>
                        </ul>

                        <div class="jiangbei">
                          <img :src="require('@/assets/image/jiangbei/jinpei.png')" alt="" />
                        </div>
                  </div>

                  <div class="backUls_box5_nr_content_3_view1 bo_color2">
                      <h1>銀獎<span>(各組別1名)</span></h1>
                      <ul>
                        <li>1,000港元現金</li>
                        <li>獎盃及獲獎證書</li>
                        <li>1份學習禮包</li>
                      </ul>
                        
                      <div class="jiangbei">
                        <img :src="require('@/assets/image/jiangbei/yinbei.png')" alt="" />
                      </div>
                  </div>

                  <div class="backUls_box5_nr_content_3_view1 bo_color3">
                      <h1>銅獎<span>(各組別1名)</span></h1>
                      <ul>
                        <li>800港元現金</li>
                        <li>獎盃及獲獎證書</li>
                        <li>1份學習禮包</li>
                      </ul>

                      <div class="jiangbei">
                        <img :src="require('@/assets/image/jiangbei/tongbei.png')" alt="" />
                      </div>
                  </div>

                  <div class="backUls_box5_nr_content_3_view2">
                        <div class="lists">
                          優異獎（若干名）
                        </div>
                        <div class="lists2"> 
                          <ul>
                            <li>獎牌及獲獎證書</li>
                            <li>1份學習禮包</li>
                          </ul>
                        </div>
                    </div>
                </div>   

                <div style="margin-top: 20px; line-height: 25px;text-align: left;font-size: 0.28rem;font-family: PingFang SC-Regular, PingFang SC;font-weight: 400;color: #E80202;"> 
                  *主辦方保留對比賽流程及相關章程的最終解釋權。如有任何爭議，主辦方保留一切最終決定權。
                </div> 
               
              </div>
            </div>
          </div>

          <!-- 占位 -->
          <div class="backUls_box6">

          </div>
        </div>
      </div>
 
      
      <!-- <div class="fontBottom" @click="baoming()">
        <span>在線報名</span>
      </div> -->
      <!-- <div class="fontBottom" @click="handleUploadVideo()">
        <img :src="require('@/assets/image/hodgepodge/anniu.png')" alt="" />
        <span>上載複賽影片</span>
      </div> -->
      <!-- <<div class="fontBottom">
        <span>複賽結束</span>
      </div> -->
    </div> 
    <!-- 复赛资料视频上传-->
		<van-popup v-model="datumZZC" :close-on-click-overlay="false" :closeable="true" :round="true" :lock-scroll="false">
			<div class="datumBox">
				<div class="titleH1">
					<div class="left_icon">
						<div></div>
						<div></div>
						<div></div>
					</div>
					<div class="title">上載參賽影片</div>
					<div class="right_icon">
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>

				<div class="stepBox">
					<div class="stepUls">
						<div class="xian" :class="stepActive >= 1 ? 'active2' : 'active'"></div>
						<div class="xian" :class="stepActive >= 2 ? 'active2' : 'active'"></div>
					</div>

					<ul>
						<li v-for="(item, index) in 3" :class="stepActive >= index ? 'active' : ''" :key="index"></li>
					</ul>
				</div>
				<div class="stepText">
					<div :class="stepActive >= 0 ? 'active' : ''">登錄</div>
					<div :class="stepActive >= 1 ? 'active' : ''">完善資料</div>
					<div :class="stepActive >= 2 ? 'active' : ''">上載影片</div>
				</div>

				<div class="datumCentent" v-if="stepActive == 0">
					<div class="tab">
						<div class="prompt" style="margin-bottom: 12px;">
							<div :class="chooseFrom == 0 ? 'highlight' : ''" @click="datumYZ(0)">參賽手機</div>
							<div :class="chooseFrom == 1 ? 'highlight' : ''" @click="datumYZ(1)">參賽電郵</div>
						</div>
						<div class="promptInput2" v-if="chooseFrom == 0">
							<div class="lis">
								<div class="lisLife">
									<!-- <img :src="require('@/assets/image/hk.png')" />
									<span>+852</span> -->
                  <CountryCodeSelector :searchbar="false" :countryCode.sync="prefix" style="width: 100px; margin-right: 15px"></CountryCodeSelector>
                </div>
								<div class="lisRight"><input type="text" placeholder="請輸入參賽電話號碼" value="" v-model.trim="from2.phone" /></div>
							</div>
						</div>
						<div class="promptInput" v-if="chooseFrom == 1"><input placeholder="請輸入參賽郵箱" v-model.trim="from2.email" /></div>
						<div class="promptInput"><input placeholder="請輸入參賽者姓名" v-model="from2.name" /></div>
					</div>
					<div class="tabTis">
						提交遇到問題？點擊右側按鈕聯系我們
						<a href="http://wa.me/85291496563" target="_blank" @click="lianxi"><img src="@/assets/image/fusai/whatsapp.png" /></a>
					</div>
					<div class="but" @click="datumActiveBut ? datumBut(0) : ''">下一步</div>
				</div>

				<div class="datumCentent" v-if="stepActive == 1">
					<div class="tab">
						<div class="prompt"  v-show="schollName == null">就讀學校</div>
						<div class="promptInput"  v-show="schollName == null"><input placeholder="請輸入就讀學校" v-model="from2.school_name" /></div>
						<div class="prompt">
							證件上傳
							<span>&nbsp;帶照片學生證/身份證明</span>
						</div>
						<div class="promptInput3"><uploaderCent @uploaderChange="uploaderImageChange" accept="image/*" uploadText="點擊上傳" logoaAtive="0"></uploaderCent></div>
					</div>

					<div class="tabTis">
						提交遇到問題？點擊右側按鈕聯系我們
						<a href="http://wa.me/85291496563" target="_blank" @click="lianxi"><img src="@/assets/image/fusai/whatsapp.png" /></a>
					</div>
					<div class="but" @click="datumBut(1)">下一步</div>
					<!-- <div class="but2" @click="getBack()">返回上一步</div> -->
				</div>

				<div class="datumCentent" v-if="stepActive == 2">
					<div class="tab">
						<div class="promptInput3">
							<uploaderCent @uploaderChange="uploaderVideoChange" accept="video/*" uploadText="點擊上載影片" v-if="wonderful == false" logoaAtive="1"></uploaderCent>
							<!-- 上傳影片成功后顯示預覽按鈕 -->
							<div class="videoYulan" v-if="videoYL">
								<div class="guanbu" @click="videoYLBut">X</div>
								<video controls :autoplay="true" :src="videoUrl"></video>
							</div>
							<div class="videoYulan2" style="cursor: pointer;" v-if="from2.video != null && wonderful == false" @click="videoYLBut"><span style="color: #000000 !important; margin-right: 10px;">影片已成功上載</span>點擊預覽視頻</div>
							<!-- 點擊預覽視頻  @click="videoYLBut"-->
							<!-- <div class="videoYulan2" v-if="from2.video && wonderful == false" @click="videoYLBut">影片已成功上載</div>

							<!-- 提交成功 -->
							<div class="wonderful" v-if="wonderful == true">
								<img src="@/assets/image/fusai/ludui.png" />
								恭喜你，成功上載影片，請耐心等待評審結果
							</div>
						</div>
					</div>
					<div class="tabTis" v-if="wonderful == false">
						提交遇到問題？點擊右側按鈕聯系我們
						<a href="http://wa.me/85291496563" target="_blank" @click="lianxi"><img src="@/assets/image/fusai/whatsapp.png" /></a>
					</div>
					<div class="but" v-if="wonderful == false" @click="datumActiveBut ? submit() : ''">提交</div>
					<div class="but" v-if="wonderful == true" @click="wonderfulBut">我知道了</div>
					<!-- <div class="but2" v-if="wonderful==false" @click="getBack()">返回上一步</div> -->
				</div>
			</div>
		</van-popup>
    <!-- 未入圍/成功入園 -->
		<van-popup v-model="newShortZZC" :round="true" :lock-scroll="false">
			<div class="newShortBox">
				<div class="list" v-if="newShortActive == 1">
					<!-- <div class="lis lisCenten"> <van-image width="149px" :src="require('@/assets/image/fusai/yes.png')" /> </div> -->
					<div class="ulst">
						<ul>
							<li>姓名</li>
							<li>組別</li>
							<li>分數</li>
						</ul>
						<ul>
							<li>{{userObj.studentName == null? '': userObj.studentName}}</li>
							<li>{{userObj.competitionGroup == null? '': userObj.competitionGroup}}</li>
							<li>{{userObj.score == null? '': userObj.score}}</li>
						</ul>
					</div> 
					<div class="lis lisText">恭喜您，成功入圍複賽，請仔細閱讀入圍電郵，上載參賽視頻。</div>
					<div class="but" @click="handelGuide">點擊查看複賽指引</div>
				</div>
		
				<div class="list" v-if="newShortActive == 2">
					<div class="ulst">
						<ul>
							<li>姓名</li>
							<li>組別</li>
							<li>分數</li>
						</ul>
						<ul>
							<li>{{userObj.studentName == null? '': userObj.studentName}}</li>
							<li>{{userObj.competitionGroup == null? '': userObj.competitionGroup}}</li>
							<li>{{userObj.score == null? '': userObj.score}}</li>
						</ul>
					</div>
					<div class="lis lisText">很遺憾，您的成績未能入圍複賽，非常感謝您的參與</div>
				</div>
			</div>
		</van-popup>
  </section>
</template>

<script>
import headers from "@/components/header/h_header";
import publics from '@/mixin/public_mixin';
import home from '@/mixin/home_mixin';
import uploaderCent from '@/components/uploaderBox/index.vue';
import CountryCodeSelector from '@/components/CountryCodeSelector';
export default {
  name: "views", 
  data() {
    return {
      tap: [
        {
          name: "正式報名",
          tiem: `即日起<br/> 至 <br/>9月22日23:59`,
          select: false,
          xuanSelect: false,
          content: {
            title: '正式報名',
            value: '官方活動網站及官方WhatsApp'
          }
        },
        {
          name: '初賽',
          tiem: `9月1日 09:00<br/> 至 <br/>9月23日 23:59`,
          select: false,
          xuanSelect: false,
          content: {
            title: '指定中文路App程式',
            value: `通過中文路App進行普通話語音測試，各組別分數排名最多前100名將獲得複賽資格，參與複賽影片錄製。`
          }
        },
        {
          name: '公佈複賽入圍名單',
          tiem: `9月27日 12:00`,
          select: false,
          xuanSelect: false,
          content: {
            title: '中文路App及官方活動網站公佈名單',
            value: '通過電郵及短信通知入圍學生。'
          }
        },
        {
          name: '複賽',
          tiem: `9月28日 09:00<br/> 至 <br/>10月13日 23:59`,
          select: false,
          xuanSelect: false,
          content: {
            title: '指定中文路App程式',
            value: `
            複賽入圍學生須使用官方指定誦材，並進行影片錄製，通過中文路App提交複賽影片，複賽各組別分數排名<span style="color:red;">前3名</span>將獲得進入總決賽資格。`
          }
        },
        {
          name: '公佈總決賽入圍名單',
          tiem: `10月20日 12:00`,
          select: false,
          xuanSelect: false,
          content: {
            title: '中文路App及官方活動網站公佈名單',
            value: '通過電郵及短信通知入圍學生。'
          }
        },
        {
          name: "總決賽暨頒獎典禮",
          tiem: `10月28日`,
          select: true,
          xuanSelect: true,
          content: {
            title: "總決賽暨頒獎典禮於將軍澳仁濟醫院王華湘中學舉行",
            value: "上半場為現場總決賽，下半場為頒獎典禮",
          },
        },
      ],
      select: 5,
      xuanSelect: 5, //当前阶段
      datumZZC:false, // 上传视频弹窗控制
			stepActive: 0, //控制流程  很重要这玩意勿搞错  参数0 1 2  表示三步骤
			datumActiveBut: true, //防止連續點擊
			wonderful: false, //成功
			videoYL: false, //預覽
			chooseFrom: 0, //驗證
			from2: {
				name: null, //名字
				school_name: null, //学校
				email: null, //邮箱
				phone: null, //手机
				certificate: null, //证件
				video: null //视频
			},
			schollName:null,//控制学校是否显示
			newShortZZC: false, //入圍 彈窗
			newShortActive: 1, //1是成功  2是失敗
			choose: 0, //查詢的下標
			from: {
				school_name: null,
				email: null,
				phone: null
			},
			finalistsBut: true, //防止連續點擊 
			userObj:{
				competitionGroup:1,//組別 
				score: null, //分數
				studentName: null,//名字
			},
      prefix: '852'
    };
  }, 
  computed: {},
  components: {
    headers,
    uploaderCent,
		CountryCodeSelector,
  },
  mixins: [publics,home],
  watch: {},
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.initScroll() // 时间轴居中
      }, 1500)
    })
  },
  methods: {
    handleUploadVideo(){
      this.datumZZC = true
    },
		datumYZ(e) {
			this.chooseFrom = e;
		},
		lianxi() {
			// 联系我们
			// window.location.href = 'http://wa.me/85291496563';
      window.open('http://wa.me/85291496563')
		},
		// 點擊上傳下一步
		datumBut(e) {
			switch (e) {
				case 0:
					// 下一步 驗證信息
					this.getUser();
					// this.stepActive = 2;
					break;
				case 1:
					// this.$toast.clear()
						// 直接下
						if (this.from2.school_name == null || this.from2.school_name == '') {
							this.$toast('请输入就讀學校 !');
							return;
						}

						if (this.from2.certificate == null || this.from2.certificate == '') {
							this.$toast('请上傳證件 !');
							return;
						}

						this.stepActive = 2;
					break;
				case 2:
					// 提交啦
					break;
				case 3:
			}
		},
		// 驗證信息
		getUser() {
			let params;
			if (this.chooseFrom == 0) {
				params = {
					studentName: this.from2.name,
					prefix: this.prefix,
					phone: this.from2.phone,
					email: null
				};
			} else if (this.chooseFrom == 1) {
				params = {
					studentName: this.from2.name,
					prefix: this.prefix,
					phone: null,
					email: this.from2.email
				};
			}

			if (params.studentName == '' || params.studentName == null) {
				this.$toast('請輸入您的姓名 !');
				return;
			}
			if (this.chooseFrom == 0) {
				// 手機
				if (params.phone == '' || params.phone == null) {
					this.$toast('手機號格式有誤 !');
					return;
				}

				if (!this.validator_phone(params.phone)) {
					this.$toast('手機號格式有誤 !');
					return;
				}
			} else if (this.chooseFrom == 1) {
				// 郵箱
				if (!this.validator_email(params.email)) {
					this.$toast('電郵格式有誤 !');
					return;
				}
			}
			// console.log('上传:', params);
			this.datumActiveBut = false;
      this.$toast.loading()
				this.$axios
				.post(this.$api.getPersonInfo,params)
				.then(response => {
					this.$toast.clear();
					this.datumActiveBut = true;
					// console.log(response);
					if (response.data.code == 500) {
						this.$toast.fail(response.data.msg);
					}
					if (response.data.code == 0) {
						this.userObj.competitionGroup=response.data.data.competitionGroup;//組別
						this.userObj.score= response.data.data.score; //分數
						this.userObj.studentName= response.data.data.studentName;//名字
						
						if (response.data.data.shortlisted) {
							this.from2.school_name = response.data.data.school;
							this.schollName = response.data.data.school == ''? null : response.data.data.school;
							// 成功
							this.stepActive = 1;
						} else {
							// 失敗
							this.datumZZC = false; //复赛资料上传弹窗
							this.stepActive = 0; //控制流程  很重要这玩意勿搞错  参数0 1 2  表示三步骤
							this.newShortZZC = true; //入圍 彈窗
							this.newShortActive = 2; //1是成功  2是失敗
						}
					}
				})
				.catch(error => {
					this.$toast.clear();
					this.datumActiveBut = true;
					//请求失败
					this.$toast.fail('error');
					console.log(error);
				});
		},
    validator_email(val) {
      const email = val.trim();
      return /^[A-Za-z0-9]+([_\-\.][A-Za-z0-9]+)*@([A-Za-z0-9\-\_]+\.)+[A-Za-z]{2,6}$/.test(
        email
      )
    },
    validator_phone(val) {
      const phone = val.trim();
      return /^[0-9]*$/.test(phone)
    },
		// 視頻
		uploaderVideoChange(e) {
			// console.log('视频', e);
			this.from2.video = e.vaidoUrl;
			this.videoUrl = e.vaidoUrl;
			// this.videoUrl = e.yuanVideo;
      this.$toast.clear();
		},
		// 圖像
		uploaderImageChange(e) {
			// console.log('图像', e);
			this.from2.certificate = e.vaidoUrl;
      this.$toast.clear();
		},
		// 視頻預覽
		videoYLBut() {
			this.videoYL = !this.videoYL;
		},
    //返回上一步
		// getBack() {
		// 	this.stepActive--;
    //   this.from2.video = null
    //   this.videoUrl = null
		// },
		// 提交
		submit() {
      const {phone,name,video,email,certificate,school_name} = this.from2
			let params = {
				phone, //手機號 
				studentName: name, //学生名字
				videoUrl: video, //学生上传的视频内容
				email, //學生電郵
        personalID:certificate,
        currentSchool:school_name,
			};

			if (params.videoUrl == null || params.videoUrl == '') {
				this.$toast('請上傳參賽作品 !');
				return;
			}

			this.datumActiveBut = false;
			this.$toast.loading({
				message: '正在提交中...',
				duration: 0,
				forbidClick: true
			});

			// 本地測試專用
			// this.$axios({
			// 	url: '/api/statistics/competition/update',
			// 	method: 'post',
			// 	data: params
			// })

				// 正式發包
				this.$axios
				.post(this.$api.update,params)
				.then(response => {
					this.datumActiveBut = true;
					// console.log(response);
					if (response.data.code == 500) {
						this.$toast.fail(response.data.msg);
					}
					if (response.data.code == 0) {
						this.$toast.clear();
						this.wonderful = true;
					}
				})
				.catch(error => {
					this.datumActiveBut = true;
					//请求失败
					this.$toast.fail('error');
					console.log(error);
				});
		},
		// 上傳成功  相當於初始化
		wonderfulBut() {
			this.wonderful = false;
			this.datumZZC = false;
			this.stepActive = 0;
			let from = {
				name: null,
				school_name: null,
				email: null,
				phone: null,
				certificate: null,
				video: null
			};
			this.from2 = from;
		},
    zhangcheng(){ 
      window.open(this.constitutionUrl,"_blank");

      // this.$toast.loading({
			// 	message: '獲取地址中...',
			// 	forbidClick: true,
			// 	duration: 0
			// });  

      // let url = 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-07/fe62588d996ef0818389f65f4192086c.pdf';
      // this.$axios({
			//   url: url,
			//   method: 'get',
			//   responseType: 'blob',
			// })
			// .then(response => {
      //   this.$toast.clear();
      //   this.$toast.success('開始下載'); 
      //   // 将文件流转化为 Blob 对象，并创建 a 标签进行下载
      //   const blob = new Blob([response.data], { type: 'application/pdf' });
      //   const downloadElement = document.createElement('a');
      //   const href = window.URL.createObjectURL(blob); // 创建下载链接
      //   downloadElement.href = href;
      //   downloadElement.download = 'zhangcheng.pdf'; // 文件名
      //   document.body.appendChild(downloadElement);
      //   downloadElement.click(); // 下载
      //   document.body.removeChild(downloadElement); // 下载完成后移除元素
      //   window.URL.revokeObjectURL(href); // 释放占用的内存空间
      // }).catch(error => {
      //   this.$toast.clear();
      //   console.error(error); // 处理异常情况
      // }); 
    },
    baoming(){
      this.$router.push('/signup') 
    },
    socai(){
      this.$router.push('/material')
    },
    tabBut(e) {
      this.select = e;
      // 触发滑动方法
      this.scrollLeftTo(e);
    },
    initScroll() {
      let target = document.getElementById('target')
      const nav = this.$refs.timeSlide
      // 计算位移偏差
      // const to =  (nav.offsetWidth - target.offsetWidth) / 2 - target.offsetLeft
      const to =  nav.offsetWidth + target.offsetWidth + target.offsetLeft
      nav.scrollLeft = to
    },
    // 滑动
    scrollLeftTo(name) {
      const ref = `tab${name}Ref`;
      const nav = this.$refs.timeSlide;
      // console.log("额:", this.$refs.timeSlide.offsetWidth);
      const title = this.$refs[ref][0];
      // 计算位移偏差
      const to = title.offsetLeft - (nav.offsetWidth - title.offsetWidth) / 2;
      nav.scrollLeft = to;
    },
  },
};
</script>

<style lang="scss" scoped>
.views {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(225deg, #ff8310 0%, #fe840f 100%);

  .views_content {
    width: 100%;
    height: calc(100% - 0.92rem);
    overflow-x: hidden;

    .views_banner {
      width: 100%;
      display: grid;

      img {
        width: 100%;
      }
    }

    .views_uls {
      width: 100%;
      background: url("~@/assets/image/hodgepodge/back.png") no-repeat;
      background-size: 100%;
      min-height: 5rem;

      .back_box_uls {
        width: 100%;

        .backUls_box {
          width: 100%;
          padding: 0.32rem;
          box-sizing: border-box;

          .ulstitle {
            width: 6.0228rem;
            height: 0.43rem;
            margin: auto;

            img {
              width: 100%;
              margin-top: -0.85rem;
            }
          }

          .uls_list {
            width: 100%;
            background: #ffffff;
            padding: 0.32rem;
            padding-bottom: 1rem;
            box-sizing: border-box;
            background: #ffffff;
            box-shadow: 0px 0.08rem 0.12rem 0px rgba(0, 0, 0, 0.05);
            border-radius: 0.3rem;
            position: relative;

            .organizersMessage {
              width: 100%;
              display: flex;
              justify-content: space-between;
              margin-bottom: 0.2rem;

              img {
                width: 1.4rem;
                height: 0.6rem;
                background: #ffffff;
                border-radius: 0.24rem;
                object-fit: contain;
                box-shadow: 0px 0.08rem 0.08rem 0px rgba(0,0,0,0.03);
              }
            }

            .posters_text {
              width: 100%;

              ul {
                width: 100%;

                li {
                  width: 100%;
                  margin-bottom: 0.16rem;
                  display: flex;
				  font-size: 0.24rem;
				  line-height: 0.4rem;
				
                  .posters_text_left {
                    width: 1.2rem; 
                    font-family: PingFang SC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #333333;
                    text-align: left; 
                  }

                  .posters_text_right {
                    width: calc(100% - 1.5rem); 
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    text-align: left;
                    
                  }
                }
              }
            }
          }
        }

        .backUls_box2::-webkit-scrollbar {
          display: none;
        }

        .backUls_box2 {
          width: 100%;
          min-height: 3rem;
          margin-top: -22%;
          background: url("~@/assets/image/hodgepodge/back2.png") no-repeat;
          background-size: 100%;
          position: relative;
          padding-top: 2.6rem;
          padding-bottom: 0.86rem;
          padding-left: 0.32rem;
          box-sizing: border-box;
          overflow-x: scroll;

          .timeBox {
            width: 100%;
            height: 0.08rem;
            // background: linear-gradient(270deg, rgba(255,255,255,0) 0%, #FFFFFF 22%, #FFFFFF 80%, rgba(255,255,255,0) 100%);
            border-radius: 0.52rem;
            white-space: nowrap;
            // display: flex;
            display: inline-flex;
            margin-bottom: 1rem;

            .timeBox_list {
              width: 2.54rem;
              height: 100%;
              background: #ffffff;
              position: relative;
              flex: 0 0 auto;
              display: flex;
              justify-content: center;
              align-items: center;

              .timeBox_dian {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                border: 0.04rem solid #ffffff;
                border-radius: 50%;
              }

              .select {
                width: 0.32rem;
                height: 0.32rem;
                background: #ffe600;
              }

              .selectdefault {
                width: 0.2rem;
                height: 0.2rem;
                background: #ffffff;
              }

              .selectTime {
                padding: 0.1rem 0.3rem;
                font-size: 0.24rem;
                text-align: center;
                box-sizing: border-box;
                background: #f78040;
                border-radius: 50px;
                border: 1px solid #fff;
                color: #fff;
              }

              .timeBox_topselect{
                // border-image-source: url("~@/assets/image/icon/qipao2.png");
                // border-image-slice: 116 76 76 76;
                // border-image-width: 1.1rem 0.8rem 0.8rem 0.8rem;
                // border-width: 0.8rem;
                // padding: 0.07rem 0.17rem 0.14rem 0.17rem;
                // box-sizing: border-box;
                // font-size: 0.28rem;
                // font-family: PingFang SC-Semibold, PingFang SC;
                // font-weight: 600;
                // color: #903300 !important; 
                // align-items:center !important;
              }

              .timeBox_top {
                width: 100%;
                height: 1rem;
                // background-color: red;
                position: absolute;
                bottom: 0.4rem;
                left: 0;

                display: flex;
                justify-content: space-around;
                align-content: flex-end;
                flex-wrap: nowrap;
                align-items: flex-end;
                font-size: 0.28rem;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;

                img {
                  width: 100%;
                  object-fit: contain;
                }
              }



              .timeBox_bottom {
                width: 100%;
                height: 1rem;
                // background-color: red;
                position: absolute;
                top: 0.4rem;
                left: 0;

                display: flex;
                justify-content: space-around;
                font-size: 0.2rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 0.35rem;
              }
            }

            .timeBox_list:first-child {
              background: linear-gradient(
                270deg,
                #ffffff 80%,
                #ffffff 22%,
                #ffffff 80%,
                rgba(255, 255, 255, 0) 100%
              );
            }

            .timeBox_list:last-child {
              background: linear-gradient(
                270deg,
                rgba(255, 255, 255, 0) 0%,
                #ffffff 80%,
                #ffffff 80%,
                #ffffff 100%
              );
            }
          }
        }

        .backUls_box3 {
          width: 100%;
          background-color: #f78041;

          ul {
            width: 100%;
            display: flex;
            padding: 0.32rem;
            box-sizing: border-box;

            li {
              padding: 0.24rem;
              box-sizing: border-box;
              width: 100%;
              min-height: 2.72rem;
              background: #ffffff;
              box-shadow: 0px 0.08rem 0.12rem 0px rgba(0, 0, 0, 0.05);
              border-radius: 0.24rem;
              text-align: left;
              position: relative;

              .backUls_box3_list {
                width: 100%;
                font-size: 0.32rem;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #f97226;
                line-height: 0.43rem;
              }

              .backUls_box3_list2 {
                width: 100%;
                font-size: 0.28rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 0.28rem;
                margin-top: 0.08rem;
                line-height: 0.44rem;

                span{
                  font-size: 0.28rem;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  line-height: 0.28rem;
                  margin-top: 0.08rem;
                  line-height: 0.44rem;
                }
              }

              .backUls_box3_list3 {
                position: absolute;
                bottom: 0.24rem;
                right: 0.24rem;
                padding: 0.08rem 0.24rem;
                box-sizing: border-box;
                border-radius: 1.6rem;
                font-size: 0.24rem;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
                line-height: 28px;

                // background: url("~@/assets/image/hodgepodge/buttom.png")
                //   no-repeat;
                // background-size: 100% 100%;
                background:#D0D0D0;
						    box-shadow: 0px 4px 9px 0px rgba(208, 208, 208, 0.4);
              }
            }
          }
        }

        .backUls_box4 {
          width: 100%;
          padding: 0.32rem;
          padding-top: 1.32rem;
          box-sizing: border-box;
          background: #ff782f;

          .backUls_box4_content {
            width: 100%;
            // height: 8.72rem;
            // background: url('~@/assets/image/hodgepodge/back3.png') no-repeat;
            background-size: 100%;
            width: 100%;
            padding: 0.32rem;
            padding-top: 0rem;
            box-sizing: border-box;
            border-image-source: url("~@/assets/image/hodgepodge/back3.png");
            border-image-slice: 84 10 10 10;
            border-image-width: 0.6rem 0.12rem 0.12rem 0.12rem;
            border-width: 0.12rem;

            .ulstitle {
              width: 6.0228rem;
              height: 0.43rem;
              margin: auto;

              img {
                width: 100%;
                margin-top: -0.85rem;
              }
            }

            .backUls_box4_nr {
              width: 100%;
              min-height: 3rem;
              background: #ffffff;
              padding: 0.32rem 0.24rem 0.48rem 0.24rem;
              box-sizing: border-box;
              background: #ffffff;
              box-shadow: 0px 0.08rem 0.12rem 0px rgba(0, 0, 0, 0.05);
              border-radius: 0.16rem;
              position: relative;

              .backUls_box4_nr_list {
                font-size: 0.26rem;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #f97226;
                line-height: 0.44rem;
                text-align: center;
                margin-bottom: 0.32rem;
              }

              .backUls_box4_nr_uls {
                width: 100%;
                padding-left: 0.24rem;
                box-sizing: border-box;
                margin-bottom: 0.32rem;

                .backUls_box4_nr_uls_list {
                  width: 100%;
                  height: 0.96rem;
                  border-radius: 0px 0px 0px 0px;
                  font-size: 0.26rem;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  line-height: 0.3rem;
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .select_bz {
                    min-width: 1.58rem;
                    padding: 0.06rem 0.36rem;
                    box-sizing: border-box;
                    background: #f97226;
                    border-radius: 1.2rem;
                    font-size: 0.26rem;
                    line-height: 0.3rem;
                    font-family: PingFang SC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #ffffff;
                    position: absolute;
                    top: 50%;
                    left: 0%;
                    transform: translate(-0.25rem, -50%);
                  }
                }

                .bz_color {
                  background: #fff3e7;
                }
                .bz_color2 {
                  background: #ffe5cc;
                }
              }

              .backUls_box4_remarks {
                width: 100%;
                font-size: 0.26rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 0.44rem;
              }
            }
          }
        }

        .backUls_box5 {
          width: 100%;
          padding: 0.32rem;
          padding-top: 1.32rem;
          box-sizing: border-box;
          background: #ff782f;

          .backUls_box5_content {
            width: 100%; 
            background-size: 100%;
            width: 100%;
            padding: 0.32rem;
            padding-top: 0rem;
            box-sizing: border-box;
            border-image-source: url("~@/assets/image/hodgepodge/back3.png");
            border-image-slice: 84 10 10 10;
            border-image-width: 0.6rem 0.12rem 0.12rem 0.12rem;
            border-width: 0.12rem; 

            .ulstitle {
              width: 6.0228rem;
              height: 0.43rem;
              margin: auto;

              img {
                width: 100%;
                margin-top: -0.85rem;
              }
            }

            .backUls_box5_nr {
              width: 100%;
              min-height: 3rem;
              background: #ffffff;
              padding: 0.32rem 0.24rem 0.48rem 0.24rem;
              box-sizing: border-box;
              background: #ffffff;
              box-shadow: 0px 0.08rem 0.12rem 0px rgba(0, 0, 0, 0.05);
              border-radius: 0.16rem;
              position: relative;

              .backUls_box5_nr_title {
                padding: 0.14rem 0.96rem;
                box-sizing: border-box;
                background: #ff9003;
                box-shadow: 0px 0px 0.14rem 0px rgba(249, 118, 47, 0.3);
                border-radius: 0.62rem;
                border: 0.04rem solid #ffffff;
                font-size: 0.32rem;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
                display: inline-block;
                margin-bottom: 0.32rem;
              }

              .backUls_box5_nr_content {
                width: 100%;
                font-size: 0.28rem;
                font-family: PingFang SC-Semibold, PingFang SC;
                color: #666666;
                line-height: 0.48rem;
                text-align: left;
                margin-bottom: 0.48rem;

                span {
                  color: #f97226;
                  font-weight: 600;
                }
              }

              .backUls_box5_nr_content:last-child {
                margin-bottom: 0px;
              }

              .backUls_box5_nr_content_2{
                width: 100%;
 
                ul{
                  width: 100%;
                  padding-left: 0.48rem;
                  box-sizing: border-box;

                  li{
                    width: 100%;
                    font-size: 0.28rem;
                    font-family: PingFang SC-Semibold, PingFang SC; 
                    color: #666666;
                    line-height: 0.48rem;
                    text-align: left;
                    margin-bottom: 0.48rem;
                    list-style: disc;
                    
                    span {
                      color: #f97226;
                      font-weight: 600;
                    }

                    font{
                      color: #E80202;
                      font-weight: 600; 
                    }
                  }
                }

                .a_view{ 
                  text-align: center;
                  font-size: 0.28rem;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #E80202;  
                  text-decoration: underline;
                }
              }

              .backUls_box5_nr_content_2:last-child {
                margin-bottom: 0px;
              }

              .backUls_box5_nr_content_3{
                width: 100%;

                .backUls_box5_nr_content_3_view1{
                  min-height: 2.82rem;
                  padding: 0.32rem;
                  box-sizing: border-box;
                  background: #FFF6F1;
                  border-radius: 0.16rem; 
                  border: 0.04rem solid #FED011;
                  position: relative;
                  margin-bottom: 0.48rem;

                  h1{
                    width: 100%;
                    display: flex;
                    font-size: 0.28rem;
                    font-family: PingFang SC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #F97226;
                    line-height: 0.36rem;

                    span{
                      color: #FF8F03;
                      margin-left: 0.2rem;
                      font-weight: 500;
                      font-size: 0.24rem;
                    }
                  }

                  ul{
                    width: 100%;
                    margin-top: 0.24rem;
                    padding-left: 0.48rem;
                    box-sizing: border-box;

                    li{
                      width: 100%;
                      font-size: 0.24rem;
                      font-family: PingFang SC-Semibold, PingFang SC; 
                      color: #666666;
                      line-height: 0.28rem;
                      text-align: left; 
                      list-style: disc; 
                      font-weight: 400;
                      margin-bottom: 0.24rem;
                    }
                  }

                  .jinpai{
                    width: 1.66rem;
                    position: absolute;
                    right: -0.35rem;
                    bottom: 0;

                    img{
                      width: 100%; 
                    }
                  }

                  .jiangbei{
                    width: 1.66rem; 
                    position: absolute; 
                    right: -0.35rem;
                    bottom: -0.1rem;

                    img{
                      width: 100%; 
                    }
                  }
                }

                .backUls_box5_nr_content_3_view2{
                  min-height: 1.96rem; 
                  background: #FFF6F1;
                  border-radius: 0.08rem; 
                  border: 0.01rem solid #FA772F;
                  position: relative;
                  margin-bottom: 0;  
                  display: flex;

                  .lists{
                    width: 2.44rem; 
                    font-size: 0.28rem;
                    font-family: PingFang SC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #F97226;
                    line-height: 0.33rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-right:0.01rem solid #FA772F; 
                  }

                  .lists2{
                    width: calc(100% - 2.44rem); 

                    ul{
                      width: 100%; 
                      height: 100%;
                      padding-left: 0.48rem;
                      box-sizing: border-box;
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      align-content: center;

                      li{
                        width: 100%;
                        font-size: 0.24rem;
                        font-family: PingFang SC-Semibold, PingFang SC; 
                        color: #666666;
                        line-height: 0.28rem;
                        text-align: left; 
                        list-style: disc; 
                        font-weight: 400;
                        margin-bottom: 0.24rem;
                      }
                    }
                  }
                }

                .bo_color{ 
                  background: #FFFCE8 !important;
                  border: 0.04rem solid #FED011 !important;
                }

                .bo_color2{
                  background: #F9FCFF !important;
                  border: 0.04rem solid #D1E1EE !important;
                } 
                .bo_color3{
                  background: #FFF4EE !important;
                  border: 0.04rem solid #FA772F !important;
                } 
              }

              .backUls_box5_nr_content_3:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }


        .backUls_box6{
          width: 100%;
          padding: 0.32rem;
          padding-top: 1.32rem;
          box-sizing: border-box;
          background: #ff782f;
        }
      }
    }  
    .fontBottom{
      height: 1rem;
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 0.16rem 0.32rem 0.1rem 0.32rem; 
      box-sizing: border-box;
      // background: rgba(255,129,57,0.28); 
      background:#D0D0D0;
      backdrop-filter: blur(15px);
      -webkit-backdrop-filter: saturate(180%) blur(15px);
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        max-height: .9rem;
      }

      span{
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        margin: auto; 
        font-size: 0.48rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF; 
        display: flex;
        align-items: center;
        justify-content: center; 
      }
    }
  }
  

	.datumBox {
    width:90vw;
    max-width:600px;
		// width: 330px;
		// height: 308px;
		padding-top: 24px;
		padding-bottom: 24px;
		box-sizing: border-box;
		background: #ffffff;
		border-radius: 16px 16px 16px 16px;

		.titleH1 {
			width: 100%;
			font-size: 18px;
			color: #ff5a25;
			font-weight: 600;
			border-radius: 8px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 11px;

			.right_icon {
				display: flex;
				align-items: center;
				div:nth-child(3) {
					display: inline-block;
					width: 4px;
					height: 4px;
					border-radius: 2px;
					background-color: #ff5a25;
					margin-left: 7px;
				}
				div:nth-child(2) {
					display: inline-block;
					width: 6px;
					height: 6px;
					border-radius: 3px;
					background-color: #ff5a25;
					margin-left: 7px;
				}
				div:first-child {
					display: inline-block;
					width: 8px;
					height: 8px;
					border-radius: 4px;
					background-color: #ff5a25;
					margin-left: 7px;
				}
			}

			.left_icon {
				display: flex;
				align-items: center;
				div:first-child {
					display: inline-block;
					width: 4px;
					height: 4px;
					border-radius: 2px;
					background-color: #ff5a25;
					margin-right: 7px;
				}
				div:nth-child(2) {
					display: inline-block;
					width: 6px;
					height: 6px;
					border-radius: 3px;
					background-color: #ff5a25;
					margin-right: 7px;
				}
				div:nth-child(3) {
					display: inline-block;
					width: 8px;
					height: 8px;
					border-radius: 4px;
					background-color: #ff5a25;
					margin-right: 7px;
				}
			}
		}

		.stepBox {
			width: 55%;
			margin: auto;
			position: relative;

			.stepUls {
				width: 100%;
				height: 30px;
				display: flex;

				.xian {
					width: 50%;
					height: 100%;
					position: relative;
				}

				.active {
					position: relative;
				}

				.active:after {
					content: ' ';
					width: 100%;
					height: 2.5px;
					background: #f5f5f5;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
				}

				.active2:after {
					content: ' ';
					width: 100%;
					height: 2.5px;
					background: #ff5a25;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
				}
			}

			ul {
				width: 100%;
				height: 30px;
				position: absolute;
				left: 0;
				top: 0;
				display: flex;
				justify-content: space-between;
				align-items: center;

				li {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background-color: #ff5a25;
					position: relative;

					div {
						position: absolute;
						bottom: -10%;
						font-size: 12px;
						font-family: PingFang SC-Regular, PingFang SC;
						font-weight: 400;
						color: #cccccc;
					}
				}

				.active {
					width: 19px;
					height: 19px;
					border-radius: 50%;
					background: url('~@/assets/image/fusai/dui.png') no-repeat;
					background-size: 100%;
				}
			}
		}

		.stepText {
			width: 70%;
			margin: auto;
			height: 30px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			div {
				font-size: 14px;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #cccccc;
			}
			div:nth-child(1) {
				margin-left: 9%;
			}

			div:nth-child(2) {
				margin-left: 16%;
			}

			div:nth-child(3) {
				margin-left: 10%;
			}
			.active {
				color: #de2810 !important;
			}
		}

		.datumCentent {
			width: 80%;
			margin: auto;
			margin-top: 15px;

			.tab {
				width: 100%;
				text-align: left;
				margin-bottom: 10px;

				.prompt {
					height: 25px;
					font-size: 14px;
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: #4d4d4d;
					line-height: 10px;
					// margin-bottom: 12px;
					// padding-left: 13px;
					// box-sizing: border-box;
					display: flex;

					div {
						margin-right: 11px;
						text-align: center;
						line-height: 20px;
						color: #999999;
					}

					.highlight {
						color: #4d4d4d !important;
						position: relative;
					}

					.highlight:after {
						content: ' ';
						width: 90%;
						height: 1px;
						background: #4d4d4d;
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						margin: auto;
					}

					span {
						font-size: 9.8px !important;
						color: #de2810;
						margin-left: 5px;
					}
				}

				.lis {
					width: 100%;
					height: 34px;
					line-height: 34px;
					background: #f5f5f5;
					display: flex;
          justify-content: space-between;
					border-radius: 6px;

					.lisLife {
						display: flex;
						align-items: center;
						font-size: 13px;
						font-family: PingFang SC-Regular, PingFang SC;
						font-weight: 400;
						color: #4a4a4a;
						padding-left: 10px;
						box-sizing: border-box;
            width:100px;
            padding-left: 15px;
						img {
							width: 16px;
							height: 11px;
              margin-right: 5px;
						}
					}

					.lisRight {
            width: calc(100% - 105px);
						height: 100%;
            display: flex;
						input {
              font-size: 16px;
							width: 100%;
							height: 34px;
							padding-left: 3px;
							box-sizing: border-box;
							border: none;
							background: #f5f5f5;
							font-size: 14px;
							font-family: PingFang SC-Regular, PingFang SC;
						}
						input::-webkit-input-placeholder {
							color: #999999;
						}
						/* 使用webkit内核的浏览器 */
						input:-moz-placeholder {
							color: #999999;
						}
						/* Firefox版本4-18 */
						input::-moz-placeholder {
							color: #999999;
						}
						/* Firefox版本19+ */
						input:-ms-input-placeholder {
							color: #999999;
						} /* IE浏览器 */
					}
				}

				.promptInput {
					width: 100%;
					height: 34px;
					box-sizing: border-box;
					background: #f5f5f5;
					border-radius: 3px 3px 3px 3px;
					margin-bottom: 12px;
          display: flex;
					input {
						width: 100%;
						height: 100%;
						font-size: 14px;
						font-family: PingFang SC-Regular, PingFang SC;
						background: #f5f5f5;
						border-radius: 3px 3px 3px 3px;
						border: none;
						padding-left: 13px;
						box-sizing: border-box;
					}

					input::-webkit-input-placeholder {
						color: #999999;
					}
					/* 使用webkit内核的浏览器 */
					input:-moz-placeholder {
						color: #999999;
					}
					/* Firefox版本4-18 */
					input::-moz-placeholder {
						color: #999999;
					}
					/* Firefox版本19+ */
					input:-ms-input-placeholder {
						color: #999999;
					} /* IE浏览器 */
				}

				.promptInput2 {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 34px;
					line-height: 34px;
					box-sizing: border-box;
					background: #f5f5f5;
					border-radius: 6px 6px 6px 6px;
					margin-bottom: 12px;
					span {
						width: 100%;
						height: 100%;
					}
					.span_tw {
						color: rgb(153, 153, 153) !important;
					}
					.promptInputValue {
						width: 100%;
						height: 100%;
						padding-left: 13px;
						box-sizing: border-box;
						border: none;
						background: #f5f5f5;
						border-radius: 6px 6px 6px 6px;
						font-size: 14px;
						font-family: PingFang SC-Regular, PingFang SC;
					}
				}

				.promptInput3 {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					background-color: #f7f8fa;
					height: 130px;
					border: 3px dotted #f5f5f5;
					position: relative;

					.wonderful {
						width: 100%;
						height: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						font-size: 12px;
						font-family: PingFang SC-Semibold, PingFang SC;
						font-weight: 600;
						color: #4d4d4d;

						img {
							width: 40px;
							height: 40px;
							border-radius: 50px;
							margin-bottom: 30px;
						}
					}

					.videoYulan {
						width: 100%;
						height: 100%;
						z-index: 999999;
						position: fixed;
						top: 0;
						left: 0;
						background: rgba(0, 0, 0, 0.8);
						display: flex;
						align-items: center;
						justify-content: center;
						overflow: hidden;

						video {
							width: 80%;
							// object-fit: cover;
							height: 100%;
						}

						.guanbu {
							position: absolute;
							top: 5%;
							right: 5%;
							width: 20px;
							height: 20px;
							color: #fff;
							font-size: 12px;
							z-index: 999;
							border-radius: 50px;
							border: 1px solid #fff;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}

					.videoYulan2 {
						position: absolute;
						bottom:3px;
						right: 0px;
						left: 0px;
						margin: auto;
						font-size: 12px;
						color: #ff5a25;
						text-align: center;
						width: 100%;
					}
				}
			}

			.tabTis {
				width: 100%;
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 12px;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #4d4d4d;
				line-height: 20px;
				margin-bottom: 50px;

				img {
					width: 30px;
					height: 30px;
					margin-left: 10px;
				}
			}

			.but {
				width: 140px;
				height: 40px;
				background: url('~@/assets/image/but.png') no-repeat;
				background-size: 100%;
				text-align: center;
				line-height: 36px;
				font-size: 14px;
				font-weight: bold;
				color: #fff;
				margin: auto;
				margin-top: 20px;
			}

			.but2 {
				width: 140px;
				height: 30px;
				text-align: center;
				font-size: 14px;
				color: #4d4d4d;
				margin: auto;
				margin-top: 10px;
			}
		}
	}
}
.newShortBox {
		width: 330px;
		// height: 308px;
		padding-top: 24px;
		padding-bottom: 24px;
		box-sizing: border-box;
		background: #ffffff;
		border-radius: 16px 16px 16px 16px;

		.list {
			width: 100%;
			height: 100%;
			display: flex;
			flex-wrap: wrap;

			.lis {
				width: 100%;
				margin-bottom: 20px;

				img {
					width: 149px;
				}
			}

			.lis:last-child {
				margin-bottom: 0px !important;
			}
			.lisText2{
				display: flex;
				justify-content: space-between;
				width: 80%;
				margin: auto;
				font-size: 14px;
				
				div{
					p{
						margin-bottom: 10px;
					}
				}
				
				span{
					color: #ff5a25;
				}
			}

			.lisCenten {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			
			.ulst{
				width: 86%;
				margin: auto;
				
				ul{
					display: flex;
					justify-content: space-between;
					width: 100%; 
					border-radius: 3px 3px 3px 3px;
					
					li{
						width: 33.3%;
						text-align: center; 
						font-size: 12px;
					}
					li:nth-child(2){
						width: 30% !important;
					}
				}
				
				ul:nth-child(1){
					background: #FF5A25;
					height: 30px;
					line-height: 30px;
					
					li{
						height: 100%;
						color: #fff;
					}
				}
				
				ul:nth-child(2){ 
					background: #FEFFEB;
					font-size: 9.8px !important;
					line-height: 20px;
					margin-top: 10px;
					margin-bottom: 20px;
					
					li{
						color: #4E4E4E;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			.lisText {
				font-size: 14px;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #4e4e4e;
				line-height: 24px;
				padding-right: 25px;
				padding-left: 25px;
				box-sizing: border-box;
			}

			.but {
				width: 140px;
				height: 40px;
				background: url('~@/assets/image/but.png') no-repeat;
				background-size: 100%;
				text-align: center;
				line-height: 36px;
				font-size: 14px;
				font-weight: bold;
				color: #fff;
				margin: auto;
			}
		}
	}
</style>
